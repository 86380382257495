import { AbstractControl, ValidationErrors } from '@angular/forms';

export function nameValidator(control: AbstractControl): ValidationErrors | null {
    const nameValue = control.value?.trim();

    // If the field is empty or contains only whitespace, skip this validation
    if (!nameValue) {
        return null;
    }

    // Regular expression to detect email-like patterns
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Regular expression to allow only letters, numbers, and spaces (no special characters)
    const specialCharPattern = /^[a-zA-Z0-9 ]+$/;

    // Check if the name contains an email pattern
    if (emailPattern.test(nameValue)) {
        return { invalidName: true }; // Return error object if validation fails
    }

    // Check if the name contains special characters
    if (!specialCharPattern.test(nameValue)) {
        return { invalidNameSpecialChar: true }; // Return error object if validation fails
    }

    return null; // Return null if validation passes
}
